import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				О нас | Откройте для себя теннисный центр Daulet
			</title>
			<meta name={"description"} content={"Где традиции встречаются со страстью на глине!"} />
			<meta property={"og:title"} content={"О нас | Откройте для себя теннисный центр Daulet"} />
			<meta property={"og:description"} content={"Где традиции встречаются со страстью на глине!"} />
			<link rel={"shortcut icon"} href={"https://solarvoxs.com/img/94119.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarvoxs.com/img/94119.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarvoxs.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			О нас
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
			В теннисном центре Daulet наша страсть к теннису отражается в каждом аспекте нашего объекта. С самого начала мы стремились создать теннисный центр, где глиняные корты служат не только полем для сражений, но и основой для развития сообщества и мастерства. Наши корты спроектированы с учетом богатой истории тенниса на глиняных кортах, обеспечивая пространство, где как новички, так и опытные игроки могут повысить уровень своей игры.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://solarvoxs.com/img/8.jpg"
						width="320px"
						max-width="100%"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://solarvoxs.com/img/9.jpg"
						width="320px"
						max-width="100%"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://solarvoxs.com/img/0.jpg"
						width="320px"
						max-width="100%"
					/>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px">
				<Button
					font="--lead"
					margin="20px"
					href="/contacts"
					type="link"
					background="--color-green"
					text-decoration-line="initial"
				>
					Посетите нас
				</Button>
			</Box>
		</Section>
		<Section padding="0px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="0px 72px 80px 72px" border-radius="24px" margin="0px 0px 0 0px" sm-padding="60px 36px 60px 36px">
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наши исключительные сооружения
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
				Daulet гордится тем, что располагает самыми современными сооружениями, отвечающими всем требованиям любителей глиняных кортов. Наши корты изготовлены из самой лучшей глины, что обеспечивает оптимальную производительность и минимальный уход. Такое внимание к деталям распространяется и на наши смотровые площадки, залы ожидания и раздевалки - все они созданы для того, чтобы улучшить ваше впечатление от игры до, во время и после нее.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-green"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						!
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Исторический дизайн
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наши корты вдохновлены классическими европейскими глиняными кортами, предлагая кусочек истории тенниса.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-green"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						!
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Экологически чистое обслуживание
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Мы используем экологически чистые материалы и методы для обслуживания наших кортов, обеспечивая устойчивое развитие спорта.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-green"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						!
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Общественные мероприятия
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Регулярные турниры и клиники дают вам возможность испытать себя и поучиться у других в благоприятной обстановке.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://solarvoxs.com/img/11.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://solarvoxs.com/img/15.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://solarvoxs.com/img/12.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://solarvoxs.com/img/13.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://solarvoxs.com/img/14.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});